.draggable-item {
  .ant-typography {
    margin-bottom: 0;
  }
}
.droppable-list {
  .list-wrapper {
    width: 100%;

    .name-item {
      width: calc(100% - 65px);
    }
    .ant-typography-edit {
      float: right;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input,
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 32px;
      height: 32px;
    }
  }
}

