.candidate-profile-form {
  //  height: calc(100vh - 120px);
  //  overflow-x: hidden;
  //  overflow-y: scroll;
  .hm-comment-edit {
    min-height: 64px;
  }
  .ant-select-single {
    height: 48px;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      height: 38px;
      line-height: 34px;
    }

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 44px;
    }
  }
}

