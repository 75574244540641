@import "../../components/Common/Comments/Comments";
@import "../../modules/massRecruitment/Screens/Auth/Auth";

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}
.ant-layout-header {
  background: @layout-header-background;
  color: @layout-header-color;
}
.ant-layout .ant-layout-sider:not(.ant-layout-sider-dark) {
  background: @layout-sider-background;
  height: 100vh;
}
#root,
.App {
  height: 100%;

  // лоадер располагается в центре контента
  // используется на главной пока загружаем профиль
  // или в любом другом месте где надо
  .full-screen-spin {
    margin-top: 45vh;
    width: 100%;

    &.suspense {
      width: 100%;
    }
  }
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.title {
  margin: 30px 0 15px;
}

.d-flex {
  display: flex;
}

.text-sm {
  font-size: @font-size-base - 2px;
}

/**
 * Button styles
 */
.btn-wrap button {
  margin-right: 20px;
  margin-bottom: 20px;
}

.ant-btn.green:not(:disabled) {
  color: #28a745;
  border-color: #28a745;
}

.ant-btn.green:not(:disabled):hover,
.ant-btn.green:not(:disabled):focus,
.ant-btn-primary.green:not(:disabled) {
  color: #fff;
  background: #28a745;
  border-color: #28a745;
}

.ant-btn-primary.green:not(:disabled):hover,
.ant-btn-primary.green:not(:disabled):focus {
  color: #fff;
  background: #218838;
  border-color: #218838;
}

.ant-btn.red:not(:disabled) {
  color: #dc3545;
  border-color: #dc3545;
}

.ant-btn.red:not(:disabled):hover,
.ant-btn.red:not(:disabled):focus,
.ant-btn-primary.red:not(:disabled) {
  color: #fff;
  background: #dc3545;
  border-color: #dc3545;
}

.ant-btn-primary.red:not(:disabled):hover,
.ant-btn-primary.red:not(:disabled):focus {
  color: #fff;
  background: #c82333;
  border-color: #c82333;
}

.ant-btn.grey:not(:disabled) {
  color: #6c757d;
  border-color: #6c757d;
}

.ant-btn.grey:not(:disabled):hover,
.ant-btn.grey:not(:disabled):focus,
.ant-btn-primary.grey:not(:disabled) {
  color: #fff;
  background: #6c757d;
  border-color: #6c757d;
}

.ant-btn-primary.grey:not(:disabled):hover,
.ant-btn-primary.grey:not(:disabled):focus {
  color: #fff;
  background: #5a6268;
  border-color: #5a6268;
}

.main__wrap {
  padding: 15px 20px;
}

.disable_day {
  background: #f9e3e3 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-layout-sider {
  .ant-menu {
    background: @layout-sider-background;
    font-size: 16px;
  }
}
.ant-form-item .ant-form-item-label >label {
  min-height: 32px;
  height: auto;
}
.ant-form-item-label label {
  white-space: normal;
}

/*
 * this styles don't apply on prod from antd so we duplicate they
*/
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: auto;
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.clickable-table .ant-table-row {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bg-white {
  background-color: @white;
}

.justify-center {
  .ant-form-item-row {
    justify-content: center;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.flex-column {
  flex-direction: column;
  width: 100%;
}

button.ant-btn-link.danger-text,
.danger-text {
  color: @danger-color;
}

.primary-text {
  &.candidate-info-heading {
    color: @blue-color;
  }
}

.overflow-visible {
  overflow: visible !important;
}

.ant-row-base {
    align-items: baseline;
}
// чтобы при первой загрузке background страницы Auth был на весь экран
.ant-layout,
.ant-layout-content {
  height: 100%;
  background: @white ;
}

.block-title {
  font-size: 20px;
  letter-spacing: 2.5px;
  position: relative;
  text-transform: uppercase;
  margin: 15px 0;

  h4 {
    margin: 0;
    min-height: 33px;
  }
  .ant-typography-edit-content {
    textarea {
      width: 100%;
    }
    margin-bottom: 0;
    inset-inline-start: 0;
  }
}
.ant-divider-horizontal {
  &.small {
    margin: 12px 0;
  }
  &.middle {
    margin: 12px 0 24px;
  }
}

.ant-modal-title {
  color: @text-color;
}
.hidden {
  display: none
}
// calendar styles override
.rbc-calendar {
  .rbc-header button,
  .rbc-toolbar button {
    font-size: 16px;
  }
  .rbc-label {
    width: 49.8203px
  }
  .rbc-events-container {
    .rbc-event {
      background: #3174ad;
      border: 1px solid #265985;
      padding-top: 0;

      &.reserve,
      &.interviewerDepartment {
        background: #3c9;
        border-color: #396;
      }

      &.rjp {
        background: #c83;
        border-color: #943;
      }

      &.hasFeedback {
        background: darkgrey;
        border-color: grey;
      }
    }

    .rbc-event-label ~ .rbc-event-content {
      display: none;
    }
  }
}

img.disabled {
  opacity: 0.3;
}

.no-gutters {
  margin: 0;
  .ant-form-item {
    margin: 0;
  }
}

.no-padding {
  padding: 0;
}

.without-addon {
  &.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 6px;
  }
  .ant-input-group-addon {
    display: none;
  }
}
.ant-layout-sider-children {
  overflow-y: auto;
  min-height: 100vh;
}
.ant-picker-range {
  width: 100%;
}
.pt-1 {
  padding-top: 4px;
}
.ant-drawer-content-wrapper {
  max-width: calc(100vw - 40px);
}
input[type='search'] {
  font-size: @font-size-base;
}
.fs-26 {
  font-size: 26px;
}
.relative-block {
  position: relative;
}
.drawer__wrap {
  padding: 30px 40px 0;
  overflow-y: auto;
  height: calc(100vh - 30px);
}

.filters-drawer-wrapper,
.userModalForm,
.drawer__wrap {
  .close {
    vertical-align: baseline;
    cursor: pointer;
  }
  .sub-title {
    margin-top: 32px;
    margin-bottom: 12px;
    text-transform: uppercase;
    color: @grey-dark-color;
  }
  .custom-search .ant-input {
    height: 27px;
  }
  .ant-picker,
  .ant-input:not(textarea) {
    height: 48px;
    border-radius: 6px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 48px;
    border-radius: 6px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 48px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    height: 48px;
    display: flex;
    align-items: center;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    height: 48px;
    display: flex;
    align-items: center;
  }

  .option-item {
    margin-bottom: 20px;
  }
}
.drawer-content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 30px);
}
.drawer-header {
  position: sticky;
  top: -30px;
  background-color: white;
  z-index: 20;
  padding-top: 30px;
  margin-top: -30px;
  padding-bottom: 1rem;
}
.drawer-footer {
  position: sticky;
  padding: 0 0 30px 0;
  bottom: 0;
  background-color: white;

  .buttons {
    padding-top: 10px;
    justify-content: flex-end;
  }
}
.candidate-drawer,
.recruiter-candidatesList-drawer,
.orgStructure-layers-edit,
.editOrgUsers-drawer,
.department-drawer,
.workflow-drawer,
.vacancy-drawer,
.analyticsFilter-drawer,
.agency-drawer {
  .ant-drawer-body {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    padding: 0;
  }
}
.ant-row-baseline {
  align-items: baseline;
}

.full-width {
  width: 100%;
}
.break-word {
  word-wrap: break-word;
}
.disabled {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number {
  width: 100%;
}
.ant-tooltip-inner {
  cursor: default;
}
.demo-wrapper {
  .rdw-editor-toolbar {
    margin-bottom: 0;
    border: 1px solid #d9d9d9;
  }

  margin-bottom: 1rem;
}
.demo-editor {
  min-height: 150px;
  border: 1px solid #d9d9d9;
  border-top: 0;
  padding: 5px 10px;
  border-radius: 2px;
  background: #ffffff;
}
.demo-wrapper.disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;

  .rdw-editor-toolbar {
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;

    .rdw-option-wrapper {
      min-width: 18px;
      height: 15px;
      cursor: not-allowed;
      opacity: .3;
      background-color: rgba(0, 0, 0, 0.18);
      border-color: #d9d9d9;

    }
  }
}
.rdw-option-wrapper{
  box-sizing: content-box;
}
